
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';


const FooterNav = () => {


    const { t } = useTranslation();

    return (
        <div className=" ">
            <div className=' '>
                <ul className="flex justify-between space-x-5 ">
                    <li className="">
                        <a rel="noopener noreferrer" href="https://www.metropoly.io/terms-of-service" className="flex items-center text-[#5B5B5B] text-[18px] xs:text-[14px] font-[400] xs:text-center sm:text-center font-Poppins hover:text-[#56aeff] text-black" >{t("Terms of Service")}</a>
                    </li>
                    <li className="">
                        <a rel="noopener noreferrer" href="https://www.metropoly.io/privacy" className="flex items-center text-[#5B5B5B] text-[18px] xs:text-[14px] font-[400] font-Poppins hover:text-[#56aeff] text-black xs:text-center sm:text-center " >{t("Privacy Policy")}</a>
                    </li>
                    <li className="">
                        <a rel="noopener noreferrer" href="https://www.metropoly.io/contact" className="flex items-center text-[#5B5B5B] text-[18px] xs:text-[14px] font-[400] font-Poppins hover:text-[#56aeff] text-black xs:text-center sm:text-center " >{t("Contact Us")}</a>
                    </li>
                    <li className="">
                        <a rel="noopener noreferrer" href="https://www.metropoly.io/metro-token-sale-agreement" className="flex items-center text-[#5B5B5B] text-[18px] xs:text-[14px] font-[400] font-Poppins hover:text-[#56aeff] text-black xs:text-center sm:text-center " >{t("Token Sale Agreement")}</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default FooterNav;
