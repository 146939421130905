import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";



const HomePage = () => {
  const { t } = useTranslation()
  return (
    <div className='container-fulid bg-[#ecf4ff]'>
      <div className='block 2xl:flex xl:flex md:flex sm:block 2xl:py-12 xl:px-20 xl:py-12 lg:px-20 lg:py-12 md:px-12 md:py-12 sm:px-12 sm:py-12 px-10 py-12 justify-between'>
        <div className='w-[100%] 2xl:w-[50%] xl:w-[47%] lg:w-[48%] md:w-[50%] sm:w-[100%] pr-5 '>
          <h2 className='font-[Poppins] text-[38px] text-black font-[700]'>{t("Win a Beautiful Burj Khalifa Apartment Worth 1 Million USD!")}  </h2>
          <br />
          <p className='font-[Poppins] text-black text-[18px]'>
            {t("Win a massive Burj Khalifa apartment worth 1 Million USD! Earn up to 100'000 USD yearly for the rest of your life!*")}
          </p><br />
          <p className='font-[Poppins] text-black text-[18px]'>
            {t("Description: We’re giving one lucky person the opportunity to own a unique property in Dubai: a Burj Khalifa apartment worth One Million USD and receive the certificate in NFTs. In addition, the winner will earn up to $100k per year from rent proceeds paid out in stablecoins like USDT. Available from anywhere worldwide and fully manageable on the Metropoly Marketplace by Metropoly.io LTD.")}
          </p><br />
          <p className='font-[Poppins] text-black text-[18px]'>
            {t("Our experienced team takes care of the property, tenants, rental payments, and more. The property can also be transferred to the new winner if he wishes.")}
          </p><br />
          <div className='pt-5'>
            <ol className='list-disc pl-8 text-[18px] font-[Poppins]'>
              <li>{t("Beautiful Burj Khalifa Penthouse worth 1 '000' 000 USD.")} </li>
              <li>{t("Up to 100'000 yearly rental income FOR LIFE!")} </li>
              <li>{t("Manageable from the Metropoly Dashboard.")} </li>
              <li>{t("Property Management included.")} </li>
              <li>{t("The Shares can be sold on the Metropoly Marketplace.")}</li>

            </ol>
          </div><br />
          <p className='font-[Poppins] text-black text-[18px]'>
            {t("This is your Financial Freedom Ticket. To win, you must complete all the tasks in this contest and hold at least $100 worth of the METRO token at the time of the draw.")}
          </p><br />
          <p className='font-[Poppins] text-black text-[18px]'>
            {t("Tip: The more friends you invite and the more METRO you own, the higher your chance of winning is.")}
          </p>
          <a href="https://presale.metropoly.io/"><button className='font-[Poppins] bg-[#2075c2] text-white px-5 py-3 rounded-md my-5 text-[18px] font-[600]'>
            {t("Buy Metro Now!")}</button></a>
          <div className='pt-5'>
            <ol className='list-decimal pl-8 text-[18px] font-[Poppins]'>
              <li>{t("Enter your crypto wallet address")}</li>
              <li>{t("Follow @metropoly_io on Twitter")}</li>
              <li>{t("Retweet @metropoly on Twitter")}</li>
              <li>{t("Tweet about Metropoly and Tag 2 friends")}</li>
              <li>{t("Join Metropoly on Telegram")}
              </li>
              <li>{t("Join our Discord community")}</li>
              <li>{t("Visit and follow Metropoly on Instagram")}</li>
              <li>{t("Share with friends for extra entries")}</li>
              <li>{t("Visit Metropoly website")}</li>
              <li>{t("Sign up for our newsletter")}</li>
            </ol>
          </div>
          <div className='py-12 space-y-3'>
            <p className='font-[Poppins] text-black text-[12px]'>

              <span className='font-[600]'> {t("Disclaimer:")}</span>  {t("These results have been achieved in the past (especially with short-term rentals).")}

            </p>
            <p className='font-[Poppins] text-black text-[12px] '>
              {t("Still, the rental income varies and depends on different factors like demand, property prices, and economic situation and cannot be guaranteed at any given time.*")}
            </p>
            <p className='font-[Poppins] text-black text-[12px]'>
              <span className='font-[600]'> {t("PLEASE NOTE:")}</span> {t("All presale stages must be successfully concluded to make this giveaway happen and ensure the prize pool. The draw will be in the first week after the presale ends, and a reliable third-party legal company in Dubai, UAE, will take care of the draw to ensure that the data is correct and the players have a fair chance to win.")}
            </p>
          </div>
        </div>
        {/* <div className='w-[39%] '>
          <a className="e-widget no-button w-[100%]" href="https://gleam.io/Zb2di/metropoly-150000-giveaway" rel="nofollow">Metropoly $150,000 Giveaway</a>
        </div> */}

        <iframe src="https://gleam.io/Zb2di/metropoly-150000-giveaway" name="iframe_a" className=' h-[1680px] 2xl:h-[1500px] xl:h-[1500px] lg:h-[1500px] md:h-[1680px] sm:h-[1500px] w-[100%] 2xl:w-[50%] xl:w-[48%] lg:w-[48%] md:w-[48%] sm:w-[100%]' title="Iframe Example"></iframe>


      </div>
    </div>
  )
}

export default HomePage;