
import React from 'react';

import Button from '../../components/Button/button';
import facebook from "../../assects/images/facebook.svg"
import Twitter from "../../assects/svgs/twittersvg.svg"
import Tme from "../../assects/svgs/tmesvg.svg"
import Discord from "../../assects/svgs/discordsvg.svg"
import Instagram from '../../assects/svgs/Instagramsvg.svg'
import FooterNav from '../FooterNav/FooterNav';
import { useTranslation } from 'react-i18next';



const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className='b h-[40vh] border-t border-gray-300 sm:mt-6 xs:mt-6'>
            <div className='px-10 sm:px-10 xs:px-6 py-6 space-y-10'>
                <div className='flex justify-center'>
                    <div className="flex  space-x-2">
                        <a href='https://www.instagram.com/metropoly_io' target="_blank">
                            <Button classes=" flex items-center justify-center border hover:border-2 border-blue-300 text-[9px]  w-[61px] h-[46px] rounded-md" wallet={Instagram} />
                        </a>
                        <a href='https://twitter.com/metropoly_io' target="_blank">
                            <Button classes="flex items-center justify-center border hover:border-2 border-blue-300 text-[9px]  w-[61px] h-[46px] rounded-md" wallet={Twitter} />
                        </a>
                        <a href='https://t.me/metropoly_official' target="_blank">
                            <Button classes=" flex items-center justify-center border hover:border-2 border-blue-300 text-[9px]  w-[61px] h-[46px] rounded-md" wallet={Tme} />
                        </a>
                        <a href='https://discord.gg/Metropoly' target="_blank">
                            <Button classes=" flex items-center justify-center border hover:border-2 border-blue-300 text-[9px]  w-[61px] h-[46px] rounded-md" wallet={Discord} />
                        </a>

                    </div>
                </div>
                <p className=" text-[18px] md:text-[14px] sm:text-[14px] xs:text-[14px] font-[400] text-[#5B5B5B] font-Poppins ">
                    <span className="mr-1 text-[18px] sm:text-[14px] xs:text-[14px] font-[700] text-[#5B5B5B] font-Poppins ">{t("RISK WARNING")}:</span>
                    {t("Metropoly.io LTD operates the website metropoly.io (“Platform”). By using the Platform, you accept our Terms of Service, Privacy Policy, Cookie Policy and METRO Token sale agreement. Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down as well as the up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction. Nothing on this website should be construed as an offer or recommendation to buy, sell or invest. You are solely responsible for determining whether any purchase or related transaction is appropriate for you based on your personal objectives, financial situation and risk tolerance. You should consult with trusted, licensed legal professionals and advisor for any legal, tax, insurance or business advice. Metropoly does not guarantee any performance, outcome or return of capital for any cryptocurrency posted on this website. By accessing this site and any pages thereof, you agree to be bound by the METRO sales agreement. Buying cryptocurrencies involve risk and may result in partial or total loss.")}

                </p>
                <div className='flex flex-col items-center space-y-4'>
                    <FooterNav />
                    <p className="flex items-center text-[#5B5B5B] text-[16px] sm:text-[14px] xs:text-[14px] font-[400] font-Poppins  text-black" >{t("Copyright 2023. All rights reserved.")}</p>
                </div>
            </div>
        </div>
    )
}
export default Footer;