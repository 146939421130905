
import React, { useState, useEffect } from 'react';
import Logo from "../../assects/svgs/metropolylogo.svg";
import { useTranslation } from 'react-i18next';
import globe from "../../assects/images/globe.png";



const NavBar = () => {

    const [langStatus, setLangStatus] = useState(false);
    const [lang, setLang] = useState("English");
    const { t, i18n } = useTranslation()
    const [url, setURL] = useState("en")

    const [trig, setTrig] = useState(false);
    useEffect(() => {


        if (window.location.href !== "") {
            if (window.location.href.slice(-2) === 'en') {
                setURL("en")
                setLang("English")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'de') {
                setLang("German")
                setURL("de")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'fr') {
                setLang("French")
                setURL("fr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'es') {
                setLang("Spanish")
                setURL("es")
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'it') {
                setLang("Italian")
                setURL("it")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'nl') {
                setLang("Dutch")
                setURL("nl")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'tr') {
                setLang("Turkish")
                setURL("tr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ko') {
                setLang("Korean")
                setURL("ko")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'nb') {
                setLang("Norwegian")
                setURL("nb")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ru') {
                setLang("Russian")
                setURL("ru")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (!trig)
                setTrig(true)
        }

    }, [trig])

    return (
        <nav className=" z-10 sticky top-0 bg-white 2xl:px-20 xl:px-20 lg:px-20 md:px-20 sm:px-4 xs:px-4   w-full  border-b border-gray-200  absolute" >
            <header className="mx-2">
                <div
                    className="flex items-center justify-between h-16 mx-auto max-w-screen-2xl sm:px-0 xs:px-0 lg:px- "
                >
                    <div className="flex items-center">
                        {/* <button type="button" onClick={handleNavbarMenu} className="p-1 sm:mr-4 xl:hidden lg:hidden 2xl:hidden ">
                            <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button> */}
                        <div className="flex items-center">
                            <a href={"https://presale.metropoly.io/?" + url} >
                                <img src={Logo} className="h-[33px]" alt="Metropoly Logo" />
                            </a>
                        </div>
                    </div>
                    <div className="flex items-center justify-end flex-1">
                        <div className='flex'>
                            <div className='flex '>
                                <ul className="items-stretch space-x-3 flex">
                                    <li>
                                        <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-black font-medium rounded-lg text-sm  py-2.5 text-center inline-flex items-center px-2"
                                            style={{
                                                border: "1px solid #313c44"
                                            }} type="button" onClick={() => { setLangStatus(!langStatus) }}>
                                            <img src={globe} width={20} className=" mr-2" />
                                            {lang}
                                            <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                                        {langStatus &&
                                            <div id="dropdown" class="z-10  mt-40 overflow-auto h-[200px] bg-white divide-y divide-gray-100 rounded-lg shadow " >
                                                <ul class="text-sm text-black " aria-labelledby="dropdownDefaultButton">
                                                    <li>
                                                        <button onClick={() => {
                                                            setLang("English")
                                                            i18n.changeLanguage('en')
                                                            setURL("en")
                                                            setLangStatus(!langStatus)
                                                        }
                                                        } style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" >{t("English")}</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => {
                                                            setLang("German")
                                                            i18n.changeLanguage('de')
                                                            setURL("de")
                                                            setLangStatus(!langStatus)
                                                        }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">German</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => {
                                                            setLangStatus(!langStatus)
                                                            setLang("French")
                                                            setURL("fr")
                                                            i18n.changeLanguage('fr')
                                                        }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">French</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => {
                                                            setLangStatus(!langStatus)
                                                            setLang("Spanish")
                                                            setURL("es")
                                                            i18n.changeLanguage('es')
                                                        }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Spanish</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => {
                                                            setLangStatus(!langStatus)
                                                            setLang("Italian")
                                                            setURL("it")
                                                            i18n.changeLanguage('it')
                                                        }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Italian</button>
                                                    </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Dutch")
                                                                setURL("nl")
                                                                i18n.changeLanguage('nl')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dutch</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Turkish")
                                                                setURL("tr")
                                                                i18n.changeLanguage('tr')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Turkish</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Korean")
                                                                setURL("ko")
                                                                i18n.changeLanguage('ko')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Korean</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Norwegian")
                                                                setURL("nb")
                                                                i18n.changeLanguage('nb')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Norwegian</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => {
                                                                setLangStatus(!langStatus)
                                                                setLang("Russian")
                                                                setURL("ru")
                                                                i18n.changeLanguage('ru')
                                                            }} style={{ width: "100%" }} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Russian</button>
                                                        </li>
                                                </ul>
                                            </div>
                                        }

                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="flex items-center">
                            <button className='px-3 mr-3 rounded-full items-center justify-start w-[160px] h-[35px] flex items-center text-[12px] p-1.5 text-sm font-semibold font-[poppins] text-white space-x-1 relative bg-[#0072BA]' onClick={handleOpen}>
                                <div className='absolute bg-white -right-3 h-[40px] w-[40px] rounded-full border border-[#0072BA] flex justify-center items-center'>
                                    <span className='  '>
                                        <img className='' src={WalletBlue} alt="" />
                                    </span>
                                </div>
                                <span>
                                    Connect Wallet
                                </span>

                            </button>



                        </div> */}
                    </div>
                </div>
            </header >
            {/* {
                menuOpen &&
                <div className="z-999 relative w-full  xl:hidden lg:hidden">
                    <ul
                        className="text-base text-gray-700 pt-4   md:pt-0"
                    >
                        {navData.map((item, id) => (
                            <li key={id} onClick={handleNavbarMenu}>
                                <a className="md:p-2 py-2 block  hover:text-[#56aeff]" href={item.navid}
                                >{item.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            } */}
        </nav >
    )
}
export default NavBar;
